.hero {
    display: flex;
    align-items: stretch;
    margin-top: 40px;
    border-radius: 40px;
    background: #FDD60C;
    height: 700px;
    color: #000000;
}

.hero-left,
.hero-right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-left {
    flex-direction: column;
}


.hero-left h1 {
    visibility: visible;
    animation-name: fadeInDown;
    color: #000;
    font-family: Montserrat;
    font-size: 52.8px;
    font-weight: 400;
}

.hero-left h1 strong {
    font-weight: 700;
}

.hero-left div div {
    margin-top: 50px;
}

.hero-right {
    margin: 11px;
    border-radius: 30px;
    background: #181818;
}

.hero-right p {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
    color: #FFF;
    font-family: Montserrat;
    font-size: 21.034px;
    font-weight: 500;
    line-height: normal;
}

.about,
.Tokenomics {
    background-color: #FDD60C;
}

.about .contetnt {
    height: 700px;
    display: flex;
    border-radius: 40px;
    background: #000;
}

.contetnt-left,
.contetnt-right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contetnt-left img {
    height: 491.92px;
}

.hero-left .btn-primary,
.hero-left .btn-primary {
    width: 101px;
    height: 41px;
}

.contetnt-right {
    padding-right: 100px;
    gap: 50px;
    flex-direction: column;
    align-items: start;
}

.about .contetnt-right h1 {
    color: #FFF;
    font-family: Montserrat;
    font-size: 48px;
    font-weight: 400;
    line-height: normal;
}

.about .contetnt-right h1 span,
.about .contetnt-right h1 b {
    color: #FDD60C;
}

.about .contetnt-right p {
    max-width: 526px;
    text-align: justify;
    color: #FFF;
    font-family: Montserrat;
    font-size: 23px;
    font-weight: 300;
    line-height: normal;
}

.Tokenomics {
    overflow-x: hidden;
}

.Tokenomics h1 {
    margin-bottom: 50px;
    font-size: 52.8px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.056px;
    text-align: center;
    color: #000000;
}

.Tokenomics .content {
    position: relative;
    margin: auto;
    width: 965px;
    height: 625px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Tokenomics .content .Center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.Tokenomics .content .Center .round-center {
    background: #FDD60C;
    border: 2px dashed #000000;
    height: 356px;
    width: 356px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Tokenomics .content .Center .round-center img {
    width: 257px;
    filter: drop-shadow(-25px 50px 40px #0000007d);

}

.arrow-left,
.arrow-right {
    position: absolute;
    width: 120px;
    height: 364px;
    border: 2px dashed #000000;
    z-index: -1;
    border-radius: 28px;
    font-family: 'Bootstrap-icons';
    color: #000000;
}

.arrow-left {
    left: -88px;
    border-left: none;
}

.arrow-left::after {
    content: "\F22D";
    position: absolute;
    top: -12.5px;
    left: 15%;
}

.arrow-left::before {
    content: "\F22D";
    position: absolute;
    bottom: -12.5px;
    left: 15%;
}

.arrow-right {
    right: -84px;
    border-right: none;
}

.arrow-right::after {
    content: "\F231";
    position: absolute;
    top: -13px;
    right: 18%;
}

.arrow-right::before {
    content: "\F231";
    position: absolute;
    bottom: -12.5px;
    right: 18%;
}

.Tokenomics .content>div {
    display: flex;
    justify-content: space-between;
}

.box-1 {
    position: relative;
    width: 238px;
    height: 238px;
    background-color: #000000;
    border-radius: 28px;
}

.box-2 {
    background-color: #000000;
    width: 238px;
    height: 238px;
    border-radius: 28px;
    border: 5px solid #FDD60C;
    padding: 30px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #FDD60C;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -7px;
    left: -7px;
    z-index: 1;
}

.box-2 span {
    color: #FFF;
}

.Roadmap {
    position: relative;
}

.Roadmap h1 {
    color: #FDD60C;
    font-size: 52.8px;
    font-weight: 700;
    letter-spacing: 1.056px;
    width: 100%;
    position: absolute;
    text-align: center;
    top: 100px;
}

.Roadmap img {
    width: 100%;
}

.GhostingOn h1,
.partner-section h1 {
    margin-bottom: 50px;
    color: #fff;
    text-align: center;
    font-size: 52.8px;
    line-height: normal;
    letter-spacing: 1.056px;
}

.GhostingOn h1 b,
.partner-section h1 b {
    color: #FDD60C;
}

.partner-card {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 25px;
    width: 238px;
    height: 238px;
    border-radius: 28.876px;
    border: 5px solid #FDD60C;
    background: #000;
    box-shadow: 0px 0px 0.9px 0px rgba(0, 0, 0, 0.25);
}

.partner-card img {
    width: 160px;
    height: 160px;
}

.Partners {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
}

.Partners .partner-card {
    margin: 0;
}
@media screen and (max-width:1400px) {
    .common-spacing {
        padding: 90px 0;
    }
    .hero,
    .about .contetnt{
        height: 450px;
    }
    .contetnt-left img{
        height: 350px;
    }
    .about .contetnt-right p{
        font-size: 20px;
    }
}
@media screen and (max-width:1200px) {
    .contetnt-right {
        padding-right: 50px;
    }

    .about .contetnt-right h1 {
        font-size: 40px;
    }

    .about .contetnt-right p {
        font-size: 18px;
    }

    .partner-card {
        width: 190px;
        height: 190px;
    }
    .Tokenomics .content{
        width: 860px;
        height: 540px;
    }
    .Tokenomics .content .Center .round-center{
        height: 300px;
        width: 300px;
    }
    .Tokenomics .content .Center .round-center img{
        width: 200px;
    }
    .arrow-left::after, .arrow-left::before{
        left: 36%;
    }
    .arrow-right::after, .arrow-right::before{
        left: 48%;
    }
    .arrow-left, .arrow-right{
        height: 300px;
    }

}
@media screen and (max-width:991px){
    .common-spacing{
        padding: 65px 0;
    }
    .hero-left h1{
        font-size: 35px;
    }
    .hero-right p{
        font-size: 18px;
    }
    .contetnt-right{
        gap: 30px;
    }
    .about .contetnt-right h1{
        font-size: 30px;
    }
    .about .contetnt-right p{
        font-size: 16px;
    }
    .Tokenomics .content{
        width: 690px;
        height: 460px;
    }

    .box-1,
    .box-2 {
        width: 170px;
        height: 170px;
        font-size: 12px;
    }

    .box-2 {
        border-width: 3px;
        top: -7px;
        left: -7px;
        padding: 15px;
    }
    .Tokenomics .content .Center .round-center{
        height: 270px;
        width: 270px;
    }
    .Tokenomics .content .Center .round-center img{
        width: 200px;
    }
    .arrow-left, .arrow-right{
        height: 270px;
    }
    .arrow-left::after, .arrow-left::before{
        left: 37%;
    }
    .arrow-right::after, .arrow-right::before{
        left: 46%;
    }
}
@media screen and (max-width:768px) {
    .common-spacing {
        padding: 50px 0;
    }

    .hero {
        width: calc(100% - 40px);
        margin: 100px auto 20px;
        flex-direction: column;
        height: 550px;
    }

    .hero-left {
        width: 100%;
        height: 50%;
    }

    .hero-right {
        width: calc(100% - 22px);
        height: 50%;
    }

    .hero-left h1 {
        font-size: 38px;
    }

    .hero-left div div {
        margin-top: 25px;
    }

    .right-box .btn-quadratic {
        padding: 20px;
        font-size: 21px;

    }

    .about .contetnt {
        height: fit-content;
        padding-bottom: 47px;
        row-gap: 50px;
        flex-direction: column;
        align-items: center;
    }

    .contetnt-left,
    .contetnt-right {
        width: calc(100% - 60px);
    }

    .contetnt-left img {
        margin-top: 50px;
        height: 250px;
    }

    .contetnt-right {
        gap: 25px;
        padding: 0;
    }

    .about .contetnt-right h1 {
        font-size: 38px;
    }

    .about .contetnt-right p {
        font-size: 16px;
    }

    .Tokenomics h1 {
        font-size: 32px;
    }

    .Tokenomics .content {
        max-width: 410px;
        width: calc(100%);
        height: 500px;
    }

    .Tokenomics .content .Center .round-center {
        width: 210px;
        height: 210px;
    }

    .Tokenomics .content .Center .round-center img {
        width: 150px;
        filter: drop-shadow(-5px 10px 35px #000000bd);
    }

    .box-1,
    .box-2 {
        width: 140px;
        height: 140px;
        font-size: 12px;
    }

    .box-2 {
        border-width: 3px;
        top: -7px;
        left: -7px;
        padding: 15px;
    }

    .arrow-right::before,
    .arrow-right::after {
        right: 50%;
    }

    .arrow-left::before,
    .arrow-left::after {
        left: 50%;
    }

    .arrow-left,
    .arrow-right {
        width: 120px;
        height: 275px;
    }

    .arrow-left {
        transform: rotate(90deg) translate(-121%, -48%);
    }

    .arrow-right {
        transform: rotate(90deg) translate(61%, 48%);
    }

    .Roadmap h1 {
        top: 45px;
        font-size: 32px;
    }

    .partner-card {
        margin: 0 10px;
    }

    .GhostingOn h1,
    .partner-section h1 {
        font-size: 32px;
    }

    .partner-card {
        width: 115px;
        height: 115px;
        border-radius: 12px;
        border: 1px solid #FDD60C;
    }

    .partner-card img {
        width: 100px;
        height: 100px;
    }

    .Partners {
        gap: 10px;
        justify-content: center;
    }
}