h1,h2,h3,h4,h5,h6,p,a {
  margin: 0;
}

a {
  text-decoration: none;
}

body {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  background: #000;
  color: #fff;
  overflow-x: hidden;
  transition: all .3s linear;
}

[class*="btn-"] {
  border-radius: 10px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  transition: all .1s linear;
}

[class*="btn-"]:hover {
  scale: 0.96;
}

.btn-primary {
  background: #000;
  padding: 15px;
  color: #FFFFFF;
  font-size: 16px;
  line-height: normal;
}

.btn-secondary {
  border: 1px solid #000;
  padding: 15px;
  color: #FDD60C;
  font-size: 16px;
  line-height: normal;
}

.btn-tertiary {
  background: #000;
  padding: 15px;
  color: #FDD60C;
  font-size: 16px;
  line-height: normal;
}

.btn-quadratic {
  border-radius: 20px;
  background: #FDD60C;
  padding: 30px;
  gap: 20px;
  color: #262626;
  font-size: 32px;
  line-height: 25px;
}

.btn-sticky {
  position: fixed;
  z-index: 999;
  border: 2px solid #FDD60C;
  bottom: 20px;
  height: 50px;
  width: 50px;
  right: 20px;
  background-color: #000;
  color: #FDD60C;
  border-radius: 50%;

}

.common-spacing {
  padding: 117px 0;
}

.Toastify {
  position: relative;
  z-index: 9999999;
}

.Toastify__toast-container {
  top: 13% !important;
}

.Toastify__toast {
  border-radius: 15px !important;
  background: #FDD60C !important;
  color: #000 !important;
}

.Toastify__toast-icon svg {
  fill: #000 !important;
}

.Toastify__close-button {
  display: none;
}

@media screen and (max-width:992px) {
  .btn-quadratic {
    border-radius: 15px;
    padding: 15px;
    font-size: 25px;
  }
}