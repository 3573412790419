.footer{
    padding: 45px;
    background: #181818;
}
.footer h1 {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.16px;
}

.footer h1 b {
    text-decoration-line: underline;
    color: #F8D20C;
}