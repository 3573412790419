.navigationbar {
    position: fixed;
    top: 0;
    width: 100%;
}

.Header_space {
    padding: 20px 0;
    height: 100px;
}

.NavBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.NavBar img {
    width: 73.94px;
    height: 60px;
}

.Token {
    display: flex;
    gap: 25px;
}

.Token input {
    width: 485px;
    height: 41px;
    background: transparent;
    border-radius: 10px;
    border: 1px solid #FDD60C;
    font-size: 16px;
    color: #FDD60C;
    padding: 15px;
    text-overflow: ellipsis;
}

.Token .btn-primary {
    background: #FDD60C;
    color: #000000;
    width: fit-content;
    height: 41px;
}
@media screen and (max-width: 992px){
    .Token input{
        width: 285px;
        font-size: 14px;
        padding: 10px;
    }
}
@media screen and (max-width: 576px) {
    .Token{
        gap: 15px;
    }
    .Token input{
        width: 136px;
    }
}